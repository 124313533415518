import { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';

export const useDateToggle = (envVarValue) => {
    const [isDatePassed, setIsDatePassed] = useState(null);

    useEffect(() => {
        const currentDate = new Date();

        const dateParts = envVarValue.split('-');
        const targetDate = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            dateParts[3] ? dateParts[3] : '',
            dateParts[4] ? dateParts[4] : ''
        );

        const hasDatePassed = isAfter(currentDate, targetDate);
        
        setIsDatePassed(hasDatePassed);
    }, [envVarValue]);

    return isDatePassed;
};

export const useToggleRac50Expired = () => {
    return useDateToggle(process.env.RACBONUS50_EXPIRE);
};

export const useSupagasInfoV3 = () => {
    return useDateToggle(process.env.SUPAGAS_INFO_V3);
};