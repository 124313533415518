import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";
import Image from 'components/common/Image';
import Faq from 'components/common/Faq';

import faqDataOffer from 'constants/components/accordion-list/supagas-info-faq';
import faqDataOfferV3 from 'constants/components/accordion-list/supagas-info-faq-v3';
import heroBanner from 'images/supagas.jpg';

import 'styles/pages/lpg-update.css';

import { useSupagasInfoV3 } from 'constants/hooks/marketingEventToggles';


function LpgPage() {
	const shouldShowSupagasInfoV3 = useSupagasInfoV3();

	return (
		<>
			{shouldShowSupagasInfoV3 === null ? (
				<div></div>
			) : shouldShowSupagasInfoV3 ? (
				<LpgPageNew />
			) : (
				<LpgPageOld />
			)}
		</>
	);
}

function LpgPageNew() {

	return (
		<>
			<SEO title="Residential LPG gas cylinders in WA | Kleenheat"
				titleBespoke="true"
				description="Kleenheat has sold its LPG distribution business to Supagas. Find out how to order a gas cylinder from Supagas and more."
			/>
			<Header />
			<LayoutMain>

				<LayoutPanel theme="mer-theme--cyan"
					background="mer-bg--primary-cyan"
					padding="mer-py-lg"
					id="supagas-hero-banner">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							<div className="mer-panel__images col-8 col-sm-4 col-lg-3">
								<img src={heroBanner}
									className="mer-panel__image supagas-logo"
									alt="Supagas logo" />
							</div>

							<div className="col-12 col-sm-10 col-lg-8 col-xl-7 pt-0">
								<div className="content text-center mer-text--size-lg">
									<h1 className="mer-panel__title mer-typography--headline2 my-0">LPG cylinder and bulk distribution business acquired by Supagas</h1>

									<p>On 2 December 2024, Supagas acquired Kleenheat’s LPG cylinder and bulk tank business that previously serviced WA and NT customers.</p>
									<p>Please refer to the Q&As below for information on how to order your LPG cylinders from Supagas.</p>
									<p className="mb-0">You can activate your Supagas account by clicking the link below, and selecting the option ‘Set up for the first time OR Forgotten your password’.</p>

									<div className="mer-panel__actions mer-button-group justify-content-center flex-column col-10 col-sm-8 col-md-6 mx-auto">
										<a href="https://www.supagas.com.au/login"
											title="Visit Supagas"
											target="_blank"
											className="mer-button mer-button--primary mr-0 mb-3">
											Activate Supagas account
										</a>
										<a href="#faq"
											title="FAQs"
											className="mer-button mer-button--secondary">
											FAQs
										</a>
									</div>

								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<LayoutPanel theme="mer-theme--light mer-border--bottom"
					padding="mer-py-lg"
					col="flex-sm-row-reverse"
					background="mer-bg--gs-grey-lighter">
					<div className="container">
						<div className="row mer-panel justify-content-center">

							<div className="col-10 col-sm-8 col-md-7 col-lg-5 content order-2">
								<h2 className="pb-0 mb-0">Pay your Kleenheat bill</h2>
								<p>If you have a Kleenheat bill, you’ll need to pay this directly to Kleenheat. You can pay through the link below using your Kleenheat customer details or call us on <a href="tel:132180" title="Call our customer service team">13 21 80</a>.</p>

								<div className="mer-panel__actions mer-button-group">
									<a href="/pay-online"
										title="Pay invoice"
										className="mer-button mer-button--primary mer--bg-primary-cyan">
										Pay bill online
									</a>
								</div>
							</div>

							<div className="mer-panel__images col-6 col-sm-4 col-md-3 col-lg-2 order-1">
								<Image className="mer-panel__image"
									src="/icons/flowmoji/flowmoji-invoice.svg"
									alt="Flowmoji invoice"
								/>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<Faq panelBackground="mer-bg--ui-light"
					panelPadding="mer-py-lg"
					id="faq"
					data={faqDataOfferV3} />

			</LayoutMain>
			<Footer />
		</>
	)
}

function LpgPageOld() {

	return (
		<>
			<SEO title="LPG update | WA's Local Provider - Kleenheat"
				titleBespoke="true"
				description="Join WA's local natural gas supplier. Enjoy award-winning local service and ongoing value with Kleenheat. Find a plan to suit your needs today."
			/>
			<Header />
			<LayoutMain>

				<LayoutPanel theme="mer-theme--cyan"
					background="mer-bg--primary-cyan"
					padding="mer-py-lg"
					id="supagas-hero-banner">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							<div className="mer-panel__images col-8 col-sm-4 col-lg-3">
								<img src={heroBanner}
									className="mer-panel__image supagas-logo"
									alt="Supagas logo" />
							</div>

							<div className="col-12 col-sm-10 col-lg-8 col-xl-7 pt-0">
								<div className="content text-center mer-text--size-lg">
									<h1 className="mer-panel__title mer-typography--headline2 my-0">LPG cylinder and bulk distribution business to be acquired by Supagas</h1>

									<p>On 2 December 2024, Supagas will acquire Kleenheat’s LPG cylinder and bulk tank business that services WA and NT customers.</p>
									<p>To facilitate this transition, Kleenheat stopped accepting cylinder orders at midday AWST on Friday 29 November. Please refer to the Q&As below for information on how to order cylinders from Supagas.</p>
									<p className="mb-0">If you have a question about an order placed with Kleenheat on or prior to 29 November, please contact us on <a href="tel:132180" title="Call our customer service team">13 21 80</a>.</p>

									<div className="mer-panel__actions mer-button-group justify-content-center flex-column col-10 col-md-5 col-lg-4 mx-auto">
										<a href="#faq"
											title="FAQs"
											className="mer-button mer-button--secondary">
											FAQs
										</a>
									</div>

								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<LayoutPanel theme="mer-theme--light mer-border--bottom"
					padding="mer-py-lg"
					col="flex-sm-row-reverse"
					background="mer-bg--gs-grey-lighter">
					<div className="container">
						<div className="row mer-panel justify-content-center">

							<div className="col-10 col-sm-8 col-md-7 col-lg-5 content order-2">
								<h2 className="pb-0 mb-0">Pay your Kleenheat bill</h2>
								<p>If you have a Kleenheat bill, you’ll need to pay this directly to Kleenheat. You can pay through the link below using your Kleenheat customer details or call us on <a href="tel:132180" title="Call our customer service team">13 21 80</a>.</p>

								<div className="mer-panel__actions mer-button-group">
									<a href="/pay-online"
										title="Pay invoice"
										className="mer-button mer-button--primary mer--bg-primary-cyan">
										Pay bill online
									</a>
								</div>
							</div>

							<div className="mer-panel__images col-6 col-sm-4 col-md-3 col-lg-2 order-1">
								<Image className="mer-panel__image"
									src="/icons/flowmoji/flowmoji-invoice.svg"
									alt="Flowmoji invoice"
								/>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<Faq panelBackground="mer-bg--ui-light"
					panelPadding="mer-py-lg"
					id="faq"
					data={faqDataOffer} />

			</LayoutMain>
			<Footer />
		</>
	)
}

export default LpgPage;